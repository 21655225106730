
import { defineComponent, ref, onMounted, computed } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiMixin";
import VueApexCharts from 'vue3-apexcharts';

export default defineComponent({
  name: "EventBarChart",
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    widgetClasses: String,
  },
  setup() {
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const baseColor = getCSSVariableValue("--bs-primary");
    const secondaryColor = getCSSVariableValue("--bs-gray-300");

    const series = ref<Array<{ name: string; data: number[] }>>([]);
    const categories = ref([]);
    const beneficiaryGenderList = ref([]);
    const currentView = ref("chart");

    const toggleView = (view) => {
      currentView.value = view;
    };

    const options = computed(() => {
      return {
        chart: {
          fontFamily: "inherit",
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: ["30%"],
            endingShape: "rounded",
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: categories.value.map(String),
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            style: {
              colors: labelColor,
              fontSize: "12px",
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: labelColor,
              fontSize: "12px",
            },
          },
        },
        fill: {
          opacity: 1,
        },
        states: {
          normal: {
            filter: {
              type: "none",
              value: 0,
            },
          },
          hover: {
            filter: {
              type: "none",
              value: 0,
            },
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: "none",
              value: 0,
            },
          },
        },
        tooltip: {
          style: {
            fontSize: "12px",
          },
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        colors: [baseColor, secondaryColor],
        grid: {
          borderColor: borderColor,
          strokeDashArray: 4,
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
      };
    });

    const loadData = async () => {
      try {
        const response = await ApiService.get(
            apiEndpoint.data().VUE_APP_DASHBOARD_DISTRICT_WISE_EVENT
        );

        const responseData = response.data.data;

        categories.value = responseData.map(item => item.district_name);
        beneficiaryGenderList.value = responseData;

        series.value = [
          {
            name: "Training",
            data: response.data.data.map(item => item.training_count),
          },
          {
            name: "Workshop",
            data: response.data.data.map(item => item.workshop_count),
          },
          {
            name: "Fgds",
            data: response.data.data.map(item => item.fgds_count),
          },
          {
            name: "Skills",
            data: response.data.data.map(item => item.skills_count),
          },
        ];
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    onMounted(loadData);

    return {
      options,
      series,
      categories,
      beneficiaryGenderList,
      currentView,
      toggleView,
    };
  },
});
