
import { defineComponent, ref, watch, computed, PropType } from "vue";

export default defineComponent({
  name: "EventGenderPieChart",
  props: {
    widgetClasses: String,
    eventData: {
      type: Array as PropType<{ label: string; data: number }[]>,
      required: true,
    },
  },
  setup(props) {
    const series = ref(props.eventData.map((item) => item.data));

    watch(() => props.eventData, (newEventData) => {
      series.value = newEventData.map((item) => item.data);
    });

    const total = computed(() => series.value.reduce((acc, value) => acc + value, 0));

    const chartOptions = computed(() => {
      return {
        chart: {
          type: "pie",
        },
        labels:  [...props.eventData.map((item) => `${item.label} - ${item.data}`), `Total - ${total.value}`],
      };
    });

    return {
      chartOptions,
      series,
    };
  },
});
