
import { defineComponent, ref, onMounted, computed } from "vue";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiMixin";
import VueApexCharts from "vue3-apexcharts";

export default defineComponent({
  name: "EventBarChart",
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    widgetClasses: String,
  },
  data() {
    return {
      beneficiaryGenderList: [] as any[],
      chartData: [
        { label: 'Wage Employment', data: 0 },
        { label: 'Self Employment', data: 0 },
        { label: 'Unemployment', data: 0 },
      ] as any[],
    };
  },
  computed: {
    chartOptions: function (this: any) {
      const beneficiaryGenderList = this.beneficiaryGenderList;
      return {
        chart: {
          type: "bar",
          stacked: true,
          height: 400,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          }
        },
        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: ["15%"],
            dataLabels: {
              enabled: true,
              position: "top",
              dataLabels: {
                total: {
                  enabled: true,
                  style: {
                    fontSize: "13px",
                    fontWeight: 900,
                  },
                },
              },
            },
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        xaxis: {
          categories: beneficiaryGenderList.map((item) => item.district_name),
        },
        yaxis: {
          categories: beneficiaryGenderList.map((item) => item.district_name),
       
        },
        legend: {
          position: "right",
          offsetY: 40,
        },
        fill: {
          opacity: 1
        }
      };
    },
    chartSeries: function (this: any) {
      return [
        {
          name: "Male",
          data: this.beneficiaryGenderList.map((item) => item.male_count),
        },
        {
          name: "Female",
          data: this.beneficiaryGenderList.map((item) => item.female_count),
        },
      ];
    },
    chartOptionsPie(this: any) {
      return {
        chart: {
          type: "pie",
        },
        labels: this.chartData.map((item) => item.label),
      };
    },
    seriesPie(this: any) {
      return this.chartData.map((item) => item.data);
    },

  },
  methods: {
    loadData: async function () {
      try {
        const response = await ApiService.post(
          apiEndpoint.data().VUE_APP_DASHBOARD_DISTRICT_WISE_GENDER,
          {
            type: "employment",
          }
        );

        this.beneficiaryGenderList = response.data.data;
        this.loadEmployementData();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    loadEmployementData: async function () {
      try {
        const response = await ApiService.post(
          apiEndpoint.data().VUE_APP_DASHBOARD_EMPLOYMENT_TYPE_WISE_GENDER,
          {}
        );   

        this.chartData = [
        { label: `Wage Employment - ${response.data.data.wage_employment_count}`, data: response.data.data.wage_employment_count },
        { label: `Self Employment - ${response.data.data.self_employment_count}`, data: response.data.data.self_employment_count },
        { label: `Unemployment - ${response.data.data.unemployment_count}`, data: response.data.data.unemployment_count },
        ];
        
      } catch (error) {
        console.error("Error fetching data:", error);
      }
},

  },
  mounted() {
    this.loadData();
    
  },
  
});
