
import { defineComponent, ref, onMounted, Ref, createApp, watch  } from "vue";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiMixin";
import { useBus } from "@/bus.ts";
import BeneficiaryGenderPieChart from "@/components/dashboard/BeneficiaryGenderPieChart.vue";
import EventGenderPieChart from "@/components/dashboard/EventGenderPieChart.vue";
import EventPieChart from "@/components/dashboard/EventPieChart.vue";
import BeneficiaryGenderChart from "@/components/dashboard/BeneficiaryGenderChart.vue";
import EventBarChart from "@/components/dashboard/EventBarChart.vue";
import BeneficiaryOccupationPieChart from "@/components/dashboard/BeneficiaryOccupationPieChart.vue";
import BeneficiaryOccupationBarChart from "@/components/dashboard/BeneficiaryOccupationBarChart.vue";
import SectorOccupationWiseBeneficiary from "@/components/dashboard/SectorOccupationWiseBeneficiary.vue";
import BeneficiaryTrainingInstitute from "@/components/dashboard/BeneficiaryTrainingInstitute.vue";
import BeneficiaryTrainingEvent from "@/components/dashboard/BeneficiaryTrainingEvent.vue";
import TrainingTypeWiseEvent from "@/components/dashboard/TrainingTypeWiseEvent.vue";
import IloToolsTraining from "@/components/dashboard/IloToolsTraining.vue";
import InnovativeTechnologyEstablished from "@/components/dashboard/InnovativeTechnologyEstablished.vue";
import SocialMarketing from "@/components/dashboard/SocialMarketing.vue";
import TourismTourist from "@/components/dashboard/TourismTourist.vue";
import Trainer from "@/components/dashboard/Trainer.vue";



export default defineComponent({
  components: {
    BeneficiaryGenderChart,
    EventGenderPieChart,
    EventBarChart,
    EventPieChart,
    BeneficiaryGenderPieChart,
    BeneficiaryOccupationPieChart,
    BeneficiaryOccupationBarChart,
    SectorOccupationWiseBeneficiary,
    BeneficiaryTrainingInstitute,
    TrainingTypeWiseEvent,
    IloToolsTraining,
    InnovativeTechnologyEstablished,
    BeneficiaryTrainingEvent,
    SocialMarketing,
    TourismTourist,
    Trainer,
  },
  mixins: [apiEndpoint],
  name: "Dashboard",
  setup(props) {
    const { bus } = useBus();
    const activeType = ref('');

    const eventData = ref<Array<{ label: string; data: number[] }>>([]);
    const genderData = ref<Array<{ label: string; data: number[] }>>([]);
    const activeComponent = ref<string | null>(null);

    const loadComponent = async (componentName, props) => {
      try {
        activeType.value = props ? props.type : '';
        const loadedComponent = await import(`@/components/dashboard/${componentName}.vue`);

        const loadComponentDiv = document.getElementById('loadComponent');
        if (loadComponentDiv) {
          loadComponentDiv.innerHTML = '';
        }

        const ComponentClass = loadedComponent.default || loadedComponent;
        const instance = createApp(ComponentClass, { genderData: genderData.value, ...props });

        instance.unmount();
        instance.mount('#loadComponent');

        activeComponent.value = componentName;
      } catch (error) {
        console.error(`Error loading component ${componentName}:`, error);
      }
    };

    const getDashBoardData = async () => {
      try {
        const response = await ApiService.get(
          apiEndpoint.data().VUE_APP_DASHBOARD_DATA
        );

        genderData.value = [
          { label: "Male", data: response.data.data.male_count },
          { label: "Female", data: response.data.data.female_count },
          { label: "Other", data: response.data.data.other_count },
        ];

        eventData.value = [
          { label: "Training", data: response.data.data.training_count },
          { label: "Workshop", data: response.data.data.workshop_count },
          { label: "Technical Meeting", data: response.data.data.technical_meeting_count },
          { label: "Fgds", data: response.data.data.fgds_count },
          { label: "Skills", data: response.data.data.skills_count },
        ];

      } catch (error) {
        console.error("Error fetching component options:", error);
      }
    };

    onMounted(() => {
      getDashBoardData();
      loadComponent('BeneficiaryGenderPieChart', {});
    });

    watch(genderData, () => {
      loadComponent('BeneficiaryGenderPieChart', {});
    });

    return {
      activeType,
      genderData,
      eventData,
      loadComponent,
      activeComponent
    };
  },
});
