
  import {defineComponent, ref, onMounted, computed, PropType, watch} from "vue";
  import ApiService from "@/core/services/ApiService";
  import { apiEndpoint } from "@/mixin/apiMixin";
  import VueApexCharts from 'vue3-apexcharts';
  
  export default defineComponent({
    name: "SocialMarketing",
    components: {
      apexchart: VueApexCharts,
    },
    props: {
      widgetClasses: String,
      type: String
    },
    setup(props) {
      const list = ref([]);
      const chartData = ref<Array<{ label: string; data: number }>>([]);
  
  
      const series = ref(chartData.value.map((item) => item.data));
  
      watch(() => chartData.value, (newGenderData) => {
        series.value = newGenderData.map((item) => item.data);
      });
  
      const total = computed(() => {
        return series.value.reduce((acc, value) => {
          if (typeof value === 'number') {
            return acc + value;
          } else {
            return acc;
          }
        }, 0);
      });
  
      const chartOptions = computed(() => {
        return {
          chart: {
            type: "pie",
          },
          labels:  [...chartData.value.map((item) => `${item.label} - ${item.data}`), `Total - ${total.value}`],
        };
      });
  
      const loadData = async () => {
        try {
          const type = props.type;
          const response = await ApiService.post(
              apiEndpoint.data().VUE_APP_DASHBOARD_INNOVATE_TECHNOLOGY_ESHTABLISH,{
                type : type
              }
          );
  
          list.value = response.data.data.ilo_tools_list_data;
  
          chartData.value = [
            { label: "Male", data: 40 },
            { label: "Female", data: 55 },
          ];
  
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
  
      onMounted(loadData);
  
      return {
        chartOptions,
        series,
        list,
      };
    },
  });
  