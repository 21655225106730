
import { defineComponent, ref, onMounted, computed  } from "vue";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiMixin";

export default defineComponent({
  name: "BeneficiaryOccupationPieChart",
  props: {
    widgetClasses: String,
  },
  setup() {
    const series = ref<number[]>([]);
    const labels = ref<string[]>([]);

    const loadHouseHoldData = async () => {
      try {
        const response = await ApiService.get(apiEndpoint.data().VUE_APP_DASHBOARD_HOUSEHOLD);

        const occupationData = response.data.data.occupation;

        // Update the ref objects directly to trigger reactivity
        labels.value = Object.entries(occupationData).map(([label, value]) => `${label.replace(/_/g, ' ')}: ${value}`);
        series.value.splice(0, series.value.length, ...(Object.values(occupationData) as number[])); // Manual type cast

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    onMounted(loadHouseHoldData);

    const chartOptions = computed(() => {
      return {
        chart: {
          type: "pie",
        },
        labels: labels.value,
      };
    });

    return {
      chartOptions,
      series,
    };
  },
});
