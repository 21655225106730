
import {defineComponent, ref, onMounted, computed, PropType, watch} from "vue";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiMixin";
import VueApexCharts from 'vue3-apexcharts';

export default defineComponent({
  name: "TourismTourist",
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    type: String,
    widgetClasses: String,
    genderData: {
      type: Array as PropType<{ label: string; data: number }[]>,
      required: true,
    },
  },
  setup(props) {
    const list = ref([]);
    const chartData = ref<Array<{ label: string; data: number }>>([]);

    const series = ref(chartData.value.map((item) => item.data));

    watch(() => chartData.value, (newGenderData) => {
      series.value = newGenderData.map((item) => item.data);
    });

    const total = computed(() => {
      return series.value.reduce((acc, value) => {
        if (typeof value === 'number') {
          return acc + value;
        } else {
          return acc;
        }
      }, 0);
    });
    const title = computed(() => {
      if (props.type === 'tourism') {
        return "Number of tourism destination committee established and operational";
      } else {
        return "Number of tourist destination services/facilities improved";
      }
    });
    const chartOptions = computed(() => {
      return {
        chart: {
          type: "pie",
        },
        labels:  [...chartData.value.map((item) => `${item.label} - ${item.data}`), `Total - ${total.value}`],
      };
    });

    const loadData = async () => {
      try {
        const type = props.type;
        
        const response = await ApiService.post(
            apiEndpoint.data().VUE_APP_DASHBOARD_TRAINING_INSTITUTE_EVENT_WISE_BENEFICIARY,{
              type : type
              
            }
        );

        list.value = response.data.data;
        console.log(list)

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    onMounted(loadData);

    return {
      chartOptions,
      series,
      list,
      title,
    };
  },
});
