
import { defineComponent, ref, onMounted, computed } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiMixin";

export default defineComponent({
  name: "BeneficiaryOccupationBarChart",
  props: {
    widgetClasses: String,
  },
  setup() {
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const baseColor = getCSSVariableValue("--bs-primary");
    const secondaryColor = getCSSVariableValue("--bs-gray-300");

    const series = ref<Array<{ name: string; data: number[] }>>([]);
    const occupationCategories = ref<string[]>(['Yes', 'No']);

    const options = computed(() => {
      return {
        chart: {
          fontFamily: "inherit",
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: ["30%"],
            endingShape: "rounded",
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: occupationCategories.value,
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            style: {
              colors: labelColor,
              fontSize: "12px",
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: labelColor,
              fontSize: "12px",
            },
          },
        },
        fill: {
          opacity: 1,
        },
        states: {
          normal: {
            filter: {
              type: "none",
              value: 0,
            },
          },
          hover: {
            filter: {
              type: "none",
              value: 0,
            },
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: "none",
              value: 0,
            },
          },
        },
        tooltip: {
          style: {
            fontSize: "12px",
          },
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        colors: [baseColor, secondaryColor],
        grid: {
          borderColor: borderColor,
          strokeDashArray: 4,
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
      };
    });

    const loadFemaleHouseHoldData = async () => {
      try {
        const response = await ApiService.get(
            apiEndpoint.data().VUE_APP_DASHBOARD_HOUSEHOLD
        );

        const femaleHouseHoldhead = response.data.data.female_house_hold_head;

        // Set categories to the keys (category names) in the data
        // categories.value = ['Yes', 'No'];

        series.value = [
          {
            name: "Yes",
            data: [femaleHouseHoldhead.Yes],
          },
          {
            name: "No",
            data: [femaleHouseHoldhead.No],
          },
        ];
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    onMounted(loadFemaleHouseHoldData);

    return {
      options,
      series,
      occupationCategories,
    };
  },
});
