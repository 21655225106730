
import {defineComponent, ref, onMounted, computed, PropType, watch} from "vue";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiMixin";
import VueApexCharts from 'vue3-apexcharts';

export default defineComponent({
  name: "BeneficiaryGenderChart",
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    widgetClasses: String,
    genderData: {
      type: Array as PropType<{ label: string; data: number }[]>,
      required: true,
    },
  },
  setup(props) {
    const beneficiaryGenderList = ref([]);
    const beneficiaryYearWiseGenderList = ref([]);

    const series = ref(props.genderData.map((item) => item.data));

    watch(() => props.genderData, (newGenderData) => {
      series.value = newGenderData.map((item) => item.data);
    });

    const total = computed(() => {
      return series.value.reduce((acc, value) => {
        if (typeof value === 'number') {
          return acc + value;
        } else {
          return acc;
        }
      }, 0);
    });

    const chartOptions = computed(() => {
      return {
        chart: {
          type: "pie",
        },
        labels:  [...props.genderData.map((item) => `${item.label} - ${item.data}`), `Total - ${total.value}`],
      };
    });

    const loadData = async () => {
      try {
        const response = await ApiService.post(
            apiEndpoint.data().VUE_APP_DASHBOARD_DISTRICT_WISE_GENDER,{}
        );
        beneficiaryGenderList.value = response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const loadYearData = async () => {
      try {
        const response = await ApiService.post(
            apiEndpoint.data().VUE_APP_DASHBOARD_YEAR_WISE_GENDER,{}
        );
        beneficiaryYearWiseGenderList.value = response.data.data;

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    onMounted(async () => {
      loadData();
      loadYearData();
    });

    return {
      chartOptions,
      series,
      beneficiaryGenderList,
      beneficiaryYearWiseGenderList,
    };
  },
});
